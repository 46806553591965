<template>
  <path
    d="M19 20L19.7172 22.1517C19.8972 22.6915 19.8386 23.2824 19.5563 23.7764L18.4437 25.7236C18.1614 26.2176 18.1028 26.8085 18.2828 27.3483L19 29.5"
    stroke="#FF595E"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M24 20L24.7172 22.1517C24.8972 22.6915 24.8386 23.2824 24.5563 23.7764L23.4437 25.7236C23.1614 26.2176 23.1028 26.8085 23.2828 27.3483L24 29.5"
    stroke="#FF595E"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M29 20L29.7172 22.1517C29.8972 22.6915 29.8386 23.2824 29.5563 23.7764L28.4437 25.7236C28.1614 26.2176 28.1028 26.8085 28.2828 27.3483L29 29.5"
    stroke="#FF595E"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M12 19.5L22.844 11.8189C23.5366 11.3283 24.4634 11.3283 25.156 11.8189L36 19.5"
    stroke="#92AFEA"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M12 34C12 33.4477 12.4477 33 13 33H35C35.5523 33 36 33.4477 36 34C36 35.1046 35.1046 36 34 36H14C12.8954 36 12 35.1046 12 34Z"
    fill="#92AFEA"
  />
</template>
